import styled from 'styled-components';
import grain from '../../assets/images/grain.png';

interface StyleProps {
    height?: number;
    width?: number;
    opacity?: number;
}

export const CardContainer = styled.div`
    display: flex;
    min-height: 462px;
    background-color: ${({ theme }) => theme.gray800};
`;

export const FilmGrain = styled.div`
    position: absolute;
    height: ${({ height }: StyleProps) => (height ? height + 'px' : '265px')};
    width: 100%;
    right: 0;
    overflow: hidden;
    opacity: ${({ opacity }: StyleProps) => opacity ?? '.3'};

    &::after {
        content: '';
        position: absolute;
        width: 200vw;
        height: 200vh;
        left: -50vw;
        top: -100vh;
        background-image: url(${grain});
        animation: grain 1.5s steps(6) infinite;
    }
`;

export const CardHover = styled.div`
    transition: all 0.2s ease 0s;
    &:hover {
        z-index: 50;
        transform: scale(1.05);
        box-shadow: 0px 0px 32px rgb(36 36 36);
    }

    &:hover ${FilmGrain} {
        display: none;
    }
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;

    div {
        padding: 8px;
    }
`;

export const Thumbnail = styled.img`
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: top;
    background-color: ${({ theme }) => theme.gray600};
`;

export const TypeLinks = styled.div`
    display: flex;
    align-items: center;

    span {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    h5 {
        color: ${({ theme }) => theme.gray500};
    }

    p {
        font-size: 14px;
        color: ${({ theme }) => theme.white};
    }

    a {
        font-size: 21px;
        color: ${({ theme }) => theme.white};
        margin-left: 9px;
        transition: all 0.3s ease-in-out 0s;

        &:hover {
            color: ${({ theme }) => theme.red};
        }
    }
`;

export const ProjectDesc = styled.div`
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 26px;
        font-weight: 600;
        font-family: 'BlenderPro', Arial !important;
        color: ${({ theme }) => theme.white};
    }

    p {
        display: flex;
        flex-direction: column;
        font-size: 17px;
        font-family: 'BlenderPro', Arial !important;
        color: ${({ theme }) => theme.gray300};
    }
    ul {
        margin-top: 22px;
        li {
            display: contents;
            list-style: none;
            font-size: 17px;
            font-family: 'BlenderPro', Arial !important;
            color: ${({ theme }) => theme.gray450};

            &::after {
                content: ', ';
            }

            &:last-child::after {
                content: '';
            }
        }
    }
`;
