import React from 'react';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';

import feedciti from '../../../../assets/images/thumbnails/feedciti.png';
import {
    CardHover,
    CardContainer,
    CardContent,
    Thumbnail,
    TypeLinks,
    ProjectDesc,
} from '../../../shared-styles/card-styles';

export function FeedCitiCard(): JSX.Element {
    return (
        <CardHover>
            <Fade bottom cascade>
                <CardContainer>
                    <CardContent>
                        <Thumbnail src={feedciti} alt='FeedCiti Image' />
                        <div>
                            <TypeLinks>
                                <span>
                                    <h5>Type</h5>
                                    <p>Mobile App + API + Hackathon</p>
                                </span>
                                <a
                                    href='https://github.com/FeedCiti'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <FaGithub />
                                </a>
                                <a
                                    href='https://devpost.com/software/feedciti'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <FaExternalLinkAlt />
                                </a>
                            </TypeLinks>
                            <ProjectDesc>
                                <h1>FeedCiti</h1>
                                <p>
                                    A centralized, community-based approach to solving
                                    issues regarding hunger at a global level.
                                </p>
                                <ul>
                                    <li>Javascript</li>
                                    <li>React Native</li>
                                    <li>Redux</li>
                                    <li>Node.js</li>
                                </ul>
                            </ProjectDesc>
                        </div>
                    </CardContent>
                </CardContainer>
            </Fade>
        </CardHover>
    );
}
