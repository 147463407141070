import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import abstractXLG from '../../../assets/images/abstractXLG.png';
import { ContentContainer } from '../../shared-styles/global.styles';
import { device } from '../../../utils/theme.util';

const ContactContainer = styled.div`
    height: 500px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: url(${abstractXLG}) no-repeat;
    background-size: 650px;
    background-position: center;
    background-color: ${({ theme }) => theme.black};
`;

const ContactContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 44px;
        color: ${({ theme }) => theme.white};
    }

    p {
        width: 53%;
        text-align: center;
        font-size: 22px;
        color: ${({ theme }) => theme.white};
        font-family: 'BlenderPro', Arial !important;
        margin-bottom: 14px;

        @media ${device.tabletL} {
            width: 84%;
        }

        @media ${device.mobileL} {
            width: 100%;
        }
    }

    span {
        display: flex;
    }

    button {
        color: ${({ theme }) => theme.white};
        border: 2px solid ${({ theme }) => theme.white};
        font-size: 15px;
        padding: 7px 15px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background-color: transparent;
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
        margin-left: 12px;

        &:hover {
            text-shadow: 1px 2px #918aff;
            border: 2px solid ${({ theme }) => theme.blue};
            color: ${({ theme }) => theme.white};
            background-color: #7cd4ff61;
        }
    }
`;

export function Contact(): JSX.Element {
    return (
        <ContactContainer id='contact'>
            <ContentContainer>
                <Fade bottom cascade>
                    <ContactContent>
                        <h1>Contact Me</h1>
                        <p>
                            Thanks for stopping by! If you'd like to reach out, you can
                            send me an email or find me on LinkedIn!
                        </p>
                        <span>
                            <a href='mailto:me@alexbarksdale.com'>
                                <button>Email</button>
                            </a>
                            <a
                                href='https://www.linkedin.com/in/alexbarksdale/'
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                <button>LinkedIn</button>
                            </a>
                        </span>
                    </ContactContent>
                </Fade>
            </ContentContainer>
        </ContactContainer>
    );
}
