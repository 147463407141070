import React, { useState } from 'react';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';

import { device } from '../../utils/theme.util';

interface StyleProps {
    open?: boolean;
}

const NavContainer = styled.div`
    height: inherit;
    width: ${({ open }: StyleProps) => (open ? '320px' : 0)};
    position: fixed;
    right: 0;
    z-index: 50;
    transition: 0.5s;
`;

const ToggleBtn = styled.button`
    display: none;
    margin-right: 20px;
    right: 0;
    z-index: 60;

    outline: none;
    font-size: 35px;
    background-color: transparent;

    svg {
        color: ${({ theme }) => theme.white};
        transition: all 0.2s ease;

        &:hover {
            filter: drop-shadow(2px 3px ${({ theme }) => theme.red});
        }
    }

    @media ${device.navigation} {
        display: flex;
    }
`;

const NavItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: ${({ theme }) => theme.gray800};
    box-shadow: 0px 0px 32px rgb(0 0 0 / 57%);

    ul {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;

        li {
            list-style: none;
            padding: 30px 0;
            white-space: nowrap;

            a {
                font-size: 25px;
                color: white;
                text-decoration: none;
                padding-left: 8px;
                border-left: 3px solid transparent;
                transition: all 0.3s ease-in-out 0s;

                &:hover {
                    border-left: 3px solid ${({ theme }) => theme.blue};
                }
            }
        }
    }
`;

export function SideNavigaton(): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);

    window.onresize = () => {
        if (open) setOpen(false);
    };

    const handleClick = () => {
        setOpen(false);
    };

    return (
        <>
            <ToggleBtn>
                <Fade top>
                    {open ? (
                        <FaTimes onClick={() => setOpen(!open)} />
                    ) : (
                        <FaBars onClick={() => setOpen(!open)} />
                    )}
                </Fade>
            </ToggleBtn>
            <NavContainer open={open}>
                <NavItems>
                    <ul>
                        <li onClick={handleClick}>
                            <a href='#about'>01. About</a>
                        </li>
                        <li onClick={handleClick}>
                            <a href='#experience'>02. Experience</a>
                        </li>
                        <li onClick={handleClick}>
                            <a href='#portfolio'>03. Portfolio</a>
                        </li>
                        <li onClick={handleClick}>
                            <a href='#articles'>04. Articles</a>
                        </li>
                        <li onClick={handleClick}>
                            <a href='#contact'>05. Contact</a>
                        </li>
                    </ul>
                </NavItems>
            </NavContainer>
        </>
    );
}
