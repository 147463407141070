import React from 'react';
import styled from 'styled-components';
import { FaMedium } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';

import { device } from '../../../utils/theme.util';

const ReadMore = styled.div`
    display: flex;
    align-items: center;

    h4 {
        transition: all 0.2s ease 0s;
        font-family: 'BlenderPro', Arial !important;
        font-size: 18px;
        color: ${({ theme }) => theme.white};
    }

    @media (max-width: 750px) {
        display: none;
    }
`;

const ArticleCard = styled.div`
    display: flex;
    min-height: 127px;
    max-height: 228px;
    margin-bottom: 9px;
    background-color: ${({ theme }) => theme.gray800};
    transition: all 0.2s ease 0s;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0px 0px 32px rgb(36 36 36);
    }

    &:hover ${ReadMore} {
        h4 {
            color: ${({ theme }) => theme.orange};
        }
    }
`;

const ArticleLink = styled.a`
    text-decoration: none;
`;

const ArticleContent = styled.div`
    display: flex;
    flex: 1;
    padding: 30px;

    @media ${device.mobileS} {
        flex-direction: column;
    }
`;

const ArticleIcon = styled.div`
    display: flex;
    align-items: center;
    margin-right: 50px;

    svg {
        font-size: 57px;
        color: ${({ theme }) => theme.white};
    }

    @media ${device.mobileS} {
        margin-bottom: 9px;
    }
`;

const ArticleDescription = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 7;

    color: ${({ theme }) => theme.white};

    h2 {
        font-size: 22px;
    }

    p {
        font-family: 'BlenderPro', Arial !important;
        font-size: 17px;
        color: ${({ theme }) => theme.gray300};
    }
`;

interface Article {
    title: string;
    description: string;
    link: string;
}

interface AppProps {
    data: Article[];
}

export function Articles({ data }: AppProps): JSX.Element {
    const renderArticles = (articles: Article[]): JSX.Element[] => {
        return articles.map((article: Article) => {
            return (
                <Fade bottom key={article.title}>
                    <li>
                        <ArticleLink
                            href={article.link}
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            <ArticleCard>
                                <ArticleContent>
                                    <ArticleIcon>
                                        <FaMedium />
                                    </ArticleIcon>

                                    <ArticleDescription>
                                        <h2>{article.title}</h2>
                                        <p>{article.description}</p>
                                    </ArticleDescription>

                                    <ReadMore>
                                        <h4>Read More</h4>
                                    </ReadMore>
                                </ArticleContent>
                            </ArticleCard>
                        </ArticleLink>
                    </li>
                </Fade>
            );
        });
    };
    return <ul>{renderArticles(data)}</ul>;
}
