import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { CategoryHeader, ContentContainer } from '../../shared-styles/global.styles';
import { Experience } from './Experience';
import experience from '../../../assets/experience.json';
import { ScrambleTxt } from '../../../utils/scramble.util';

const ExperienceContainer = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.black};
    padding: 190px 0px 50px 0px;
    overflow: hidden;
`;

export function ExperienceContent(): JSX.Element {
    return (
        <ExperienceContainer id='experience'>
            <Fade delay={500}>
                <ScrambleTxt text='457870657269656e6365' />
            </Fade>
            <ContentContainer>
                <CategoryHeader>
                    <h1>Experience</h1>
                    <p>Places I've Worked.</p>
                </CategoryHeader>
                <Experience data={experience} />
            </ContentContainer>
        </ExperienceContainer>
    );
}
