import React from 'react';
import { FaGithub } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';

import unilyfe from '../../../../assets/images/thumbnails/unilyfe.png';
import {
    CardHover,
    CardContainer,
    CardContent,
    Thumbnail,
    TypeLinks,
    ProjectDesc,
} from '../../../shared-styles/card-styles';

export function UniLyfeCard(): JSX.Element {
    return (
        <CardHover>
            <Fade bottom cascade>
                <CardContainer>
                    <CardContent>
                        <Thumbnail src={unilyfe} alt='UniLyfe Image' />
                        <div>
                            <TypeLinks>
                                <span>
                                    <h5>Type</h5>
                                    <p>Web app + API</p>
                                </span>
                                <a
                                    href='https://github.com/alexbarksdale/UniLyfe'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <FaGithub />
                                </a>
                            </TypeLinks>
                            <ProjectDesc>
                                <h1>UniLyfe</h1>
                                <p>
                                    A platform for verified university students to
                                    network, chat, and share their ideas while remaining
                                    anonymous.
                                </p>
                                <ul>
                                    <li>Typescript</li>
                                    <li>React</li>
                                    <li>Redux</li>
                                    <li>Node.js</li>
                                    <li>Express.js</li>
                                    <li>Apollo GraphQL</li>
                                    <li>PostgreSQL</li>
                                </ul>
                            </ProjectDesc>
                        </div>
                    </CardContent>
                </CardContainer>
            </Fade>
        </CardHover>
    );
}
