import React from 'react';
import { FaGithub } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';

import bubl from '../../../../assets/images/thumbnails/bubl.png';
import {
    CardHover,
    CardContainer,
    CardContent,
    Thumbnail,
    TypeLinks,
    ProjectDesc,
} from '../../../shared-styles/card-styles';

export function BublCard(): JSX.Element {
    return (
        <CardHover>
            <Fade bottom cascade>
                <CardContainer>
                    <CardContent>
                        <Thumbnail src={bubl} alt='Bubl Image' />
                        <div>
                            <TypeLinks>
                                <span>
                                    <h5>Type</h5>
                                    <p>CLI tool</p>
                                </span>
                                <a
                                    href='https://github.com/alexbarksdale/bubl'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <FaGithub />
                                </a>
                            </TypeLinks>
                            <ProjectDesc>
                                <h1>bubl</h1>
                                <p>
                                    Bubl is a flexible and easy-to-use boilerplate
                                    generator. Bubl lets you create a template from any
                                    file or directory on your system.
                                </p>
                                <ul>
                                    <li>Go</li>
                                </ul>
                            </ProjectDesc>
                        </div>
                    </CardContent>
                </CardContainer>
            </Fade>
        </CardHover>
    );
}
