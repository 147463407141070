import React from 'react';
import styled from 'styled-components';

import { Container } from '../shared-styles/global.styles';
import { SocialGutter } from './SocialGutter';

const GutterContainer = styled.div`
    position: relative;
`;

export function Gutters(): JSX.Element {
    return (
        <Container>
            <GutterContainer>
                <SocialGutter />
            </GutterContainer>
        </Container>
    );
}
