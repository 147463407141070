import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

import { ContentContainer } from "../../shared-styles/global.styles";
import { device } from "../../../utils/theme.util";

const IntroContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: -65px;

    height: 100vh;
    position: relative;
    background: linear-gradient(
        360deg,
        ${({ theme }) => theme.black} 27%,
        rgba(255, 255, 255, 0) 43%
    );
`;

const IntroContent = styled.div`
    position: relative;
    h1 {
        z-index: 20;
        font-size: 65px;
        color: ${({ theme }) => theme.white};

        @media ${device.mobileS} {
            font-size: 55px;
        }
    }
    hr {
        height: 6px;
        width: 75px;
        margin-bottom: 12px;
        border: none;
        background-color: ${({ theme }) => theme.yellow};
    }
`;

const MiddleText = styled.h2`
    font-size: 110px;
    margin-top: -170px;
    position: absolute;
    color: #dbdbdb30;
    @media ${device.mobileS} {
        font-size: 75px;
        margin-top: -140px;
    }
`;

const SubText = styled.p`
    font-size: 22px;
    font-family: "BlenderPro", Arial !important;
    color: ${({ theme }) => theme.white};
    margin-bottom: 9px;
`;

const ResumeBtn = styled.button`
    color: ${({ theme }) => theme.white};
    border: 2px solid ${({ theme }) => theme.white};
    font-size: 15px;
    padding: 7px 15px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: transparent;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;

    &:hover {
        text-shadow: 1px 2px #918aff;
        border: 2px solid ${({ theme }) => theme.blue};
        color: ${({ theme }) => theme.white};
        background-color: #7cd4ff61;
    }
`;

// Credit: https://codepen.io/xonic/pen/KWMaqe
const ScrollDown = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    margin-top: 104px;

    div {
        position: absolute;
        width: 28px;
        height: 8px;
        opacity: 0;
        transform: scale3d(0.5, 0.5, 0.5);
        animation: move 3s ease-out infinite;

        &:first-child {
            animation: move 3s ease-out 1s infinite;
        }

        &:nth-child(2) {
            animation: move 3s ease-out 2s infinite;
        }

        &:before,
        &:after {
            content: " ";
            position: absolute;
            top: 0;
            height: 100%;
            width: 51%;
            background: #fff;
        }

        &:before {
            left: 0;
            transform: skew(0deg, 30deg);
        }

        &:after {
            right: 0;
            width: 50%;
            transform: skew(0deg, -30deg);
        }

        @keyframes move {
            25% {
                opacity: 1;
            }
            33% {
                opacity: 1;
                transform: translateY(30px);
            }
            67% {
                opacity: 1;
                transform: translateY(40px);
            }
            100% {
                opacity: 0;
                transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
            }
        }
    }
`;

export function HeaderContent(): JSX.Element {
    return (
        <IntroContainer>
            <ContentContainer>
                <Fade delay={500}>
                    <IntroContent>
                        <Fade top>
                            <h1>
                                Alex
                                <br />
                                Barksdale
                            </h1>
                            <hr />
                        </Fade>
                        <Fade right cascade>
                            <MiddleText>健二</MiddleText>
                        </Fade>
                        <Fade bottom cascade>
                            <SubText>Software Engineer II at Datadog</SubText>
                        </Fade>
                        <Fade bottom cascade>
                            <a
                                href="resume.pdf"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <ResumeBtn>Resume</ResumeBtn>
                            </a>
                        </Fade>
                        <ScrollDown>
                            <div />
                            <div />
                            <div />
                        </ScrollDown>
                    </IntroContent>
                </Fade>
            </ContentContainer>
        </IntroContainer>
    );
}
