import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

import { ContentContainer } from "../../shared-styles/global.styles";
import { device } from "../../../utils/theme.util";
import profileImg from "../../../assets/images/profileImg.jpeg";
import { FilmGrain } from "../../shared-styles/card-styles";
import abstractX from "../../../assets/images/abstractX.png";

const AboutContainer = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.black};
    padding: 150px 0px;
    overflow: hidden;
`;

const Texture = styled.img`
    height: 123px;
    margin: -66px;
    position: relative;
`;

const AboutContent = styled.div`
    display: flex;

    div {
        display: flex;
        flex-direction: column;
        z-index: 50;
    }
    h1 {
        display: flex;
        align-items: center;
        font-size: 36px;
        color: ${({ theme }) => theme.white};

        hr {
            height: 1px;
            width: 75px;
            border: none;
            margin-left: 15px;
            background-color: ${({ theme }) => theme.gray600};
        }
    }

    p {
        font-size: 18px;
        font-family: "BlenderPro", Arial !important;
        color: ${({ theme }) => theme.white};

        a {
            font-size: 18px;
            font-family: "BlenderPro", Arial !important;
            color: ${({ theme }) => theme.white};
            font-weight: 600;
            transition: all 0.3s ease-in-out 0s;

            &:hover {
                color: ${({ theme }) => theme.pink};
            }
        }
    }

    h2 {
        font-size: 18px;
        font-family: "BlenderPro", Arial !important;
        color: ${({ theme }) => theme.white};
        margin-top: 15px;
    }

    ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        li {
            list-style: none;
            font-size: 18px;
            font-family: "BlenderPro", Arial !important;
            word-break: break-all;
            color: ${({ theme }) => theme.white};
        }

        @media ${device.tabletM} {
            grid-template-columns: 1fr;
        }
        @media ${device.tabletS} {
            grid-template-columns: repeat(2, 1fr);
        }
        @media ${device.mobileS} {
            grid-template-columns: 1fr;
        }
    }

    @media ${device.tabletS} {
        flex-direction: column;
    }
`;

const ProfileImg = styled.img`
    width: 250px;
    border: 4px solid transparent;
    margin: 0px 30px 10px 0px;

    background-image: linear-gradient(white, white),
        linear-gradient(
            360deg,
            rgba(255, 140, 230, 1) 0%,
            rgba(0, 240, 255, 1) 28%,
            rgba(253, 255, 169, 1) 53%,
            rgba(255, 116, 116, 1) 80%,
            rgba(158, 255, 150, 1) 100%
        );
    background-repeat: no-repeat;
    background-size: 100% 100%, 100% 200%;
    background-position: 0 0, 0 100%;
    background-origin: padding-box, border-box;
    animation: highlight 2.5s infinite alternate;

    @keyframes highlight {
        100% {
            background-position: 0 0, 0 0;
        }
    }

    &:after {
        animation: grain 8s steps(10) infinite;
        content: "";
        height: 300%;
        left: -50%;
        opacity: 0.3;
        position: fixed;
        top: -100%;
        width: 300%;
    }

    @media ${device.tabletL} {
        width: 197px;
    }
`;

export function About(): JSX.Element {
    return (
        <AboutContainer id="about">
            <ContentContainer>
                <Fade delay={500}>
                    <Texture src={abstractX} />
                </Fade>
                <AboutContent>
                    <Fade left style={{ positon: "absolute" }}>
                        <>
                            <FilmGrain height={342} />
                            <ProfileImg src={profileImg} alt="Profile Image" />
                        </>
                    </Fade>
                    <Fade right>
                        <div>
                            <h1>
                                About Me
                                <hr />
                            </h1>
                            <p>
                                I studied Computer Science at{" "}
                                Dominican University of California.
                                I enjoy working with a wide variety of
                                technologies and projects that require me to
                                work outside of my knowledge set. Besides
                                programming, I'm a gamer geek and fitness
                                enthusiast.
                            </p>

                            <h2>Technologies I work with:</h2>
                            <Fade top cascade>
                                <ul>
                                    <li>• Typescript/Javascript</li>
                                    <li>• Python</li>
                                    <li>• Go</li>
                                    <li>• React/Redux</li>
                                    <li>• PostgreSQL</li>
                                    <li>• GraphQL</li>
                                    <li>• gRPC</li>
                                    <li>• Apache Kafka</li>
                                </ul>
                            </Fade>
                        </div>
                    </Fade>
                </AboutContent>
            </ContentContainer>
        </AboutContainer>
    );
}
