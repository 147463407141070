export type Theme = {
    white: string;
    black: string;
    darkGray: string;
    blue: string;
    yellow: string;
    pink: string;
    red: string;
    orange: string;
    shadow: string;
    gray200: string;
    gray300: string;
    gray350: string;
    gray400: string;
    gray450: string;
    gray500: string;
    gray600: string;
    gray800: string;
};

// Theme color for the sites
export const theme: Theme = {
    white: '#fff',
    black: '#000',
    darkGray: '#000',
    blue: '#00f3ff',
    yellow: '#F6FF8A',
    pink: '#f88aff',
    red: '#ff7373',
    orange: '#ffcc59',
    shadow: '#e4e4e4',
    gray200: '#f3f3f3',
    gray300: '#e9e9e9',
    gray350: '#cecece',
    gray400: '#bbbbbb',
    gray450: '#9e9e9e',
    gray500: '#838383',
    gray600: '#595959',
    gray800: '#202022',
};

// Screen sizes for devices
const size = {
    mobileXS: '392px',
    mobileS: '500px',
    mobileL: '590px',
    tabletS: '665px',
    tabletM: '770px',
    navigation: '825px',
    tabletL: '960px',
    laptopS: '1172px',
    laptopM: '1262px',
    laptopL: '1442px',
};

// Media queries
export const device = {
    mobileXS: `(max-width: ${size.mobileXS})`,
    mobileS: `(max-width: ${size.mobileS})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tabletS: `(max-width: ${size.tabletS})`,
    tabletM: `(max-width: ${size.tabletM})`,
    navigation: `(max-width: ${size.navigation})`,
    tabletL: `(max-width: ${size.tabletL})`,
    laptopS: `(max-width: ${size.laptopS})`,
    laptopM: `(max-width: ${size.laptopM})`,
    laptopL: `(max-width: ${size.laptopL})`,
};
