import React from 'react';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';

import cleanearth from '../../../../assets/images/thumbnails/cleanearth.png';
import {
    CardHover,
    CardContainer,
    CardContent,
    Thumbnail,
    TypeLinks,
    ProjectDesc,
} from '../../../shared-styles/card-styles';

export function CleanEarthCard(): JSX.Element {
    return (
        <CardHover>
            <Fade bottom cascade>
                <CardContainer>
                    <CardContent>
                        <Thumbnail src={cleanearth} alt='Clean Earth Image' />
                        <div>
                            <TypeLinks>
                                <span>
                                    <h5>Type</h5>
                                    <p>Web App + API + Tensorflow + Hackathon</p>
                                </span>
                                <a
                                    href='https://github.com/CleanEarthIO'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <FaGithub />
                                </a>
                                <a
                                    href='https://devpost.com/software/cleanearth'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <FaExternalLinkAlt />
                                </a>
                            </TypeLinks>
                            <ProjectDesc>
                                <h1>Clean Earth</h1>
                                <p>
                                    CleanEarth is a platform that acts as a centralized,
                                    community-based approach to cleaning up trash in
                                    communities.
                                </p>
                                <ul>
                                    <li>
                                        Typescript, React, Redux, Python, Flask,
                                        Tensorflow, Keras
                                    </li>
                                </ul>
                            </ProjectDesc>
                        </div>
                    </CardContent>
                </CardContainer>
            </Fade>
        </CardHover>
    );
}
