import React from 'react';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle } from './components/shared-styles/global.styles';
import { theme } from './utils/theme.util';
import { Navigation } from './components/navigation/Navigation';
import { Gutters } from './components/gutters/Gutters';
import { HeaderContent } from './components/sections/header-content/HeaderContent';
import { About } from './components/sections/about/About';
import { Portfolio } from './components/sections/portfolio/Portfolio';
import { ArticleContent } from './components/sections/article-content/ArticleContent';
import { Contact } from './components/sections/contact/Contact';
import { ExperienceContent } from './components/sections/experience/ExperienceContent';

export function App() {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Navigation />
            <Gutters />
            <HeaderContent />
            <About />
            <ExperienceContent />
            <Portfolio />
            <ArticleContent />
            <Contact />
        </ThemeProvider>
    );
}
