import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { device } from '../../../utils/theme.util';

const ExperienceCard = styled.div`
    display: flex;
    min-height: 127px;
    margin-bottom: 9px;
    background-color: ${({ theme }) => theme.gray800};
    transition: all 0.2s ease 0s;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0px 0px 32px rgb(36 36 36);
    }
`;

const ExperienceContent = styled.div`
    display: flex;
    flex: 1;
    padding: 30px;

    @media ${device.mobileL} {
        flex-direction: column;
    }
`;

const ExperienceIcon = styled.div`
    display: flex;
    align-items: center;
    margin-right: 50px;

    img {
        border-radius: 8px;
        height: 64px;
        width: 64px;

        @media ${device.mobileL} {
            margin-bottom: 9px;
        }
    }
`;

const ExperienceDescription = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    color: ${({ theme }) => theme.white};

    h2 {
        font-size: 22px;
    }

    h5 {
        color: ${({ theme }) => theme.gray400};
    }

    ul {
        margin-top: 7px;
        text-indent: 20px;

        li {
            p {
                font-family: 'BlenderPro', Arial !important;
                font-size: 17px;
                color: ${({ theme }) => theme.gray300};
            }
        }
    }
`;

interface Experience {
    title: string;
    description: string;
    image: string;
    date: string;
    keyPoints?: {
        one?: string;
        two?: string;
        three?: string;
    };
}

interface AppProps {
    data: Experience[];
}

export function Experience({ data }: AppProps): JSX.Element {
    const renderExperience = (articles: Experience[]): JSX.Element[] => {
        return articles.map((article: Experience) => {
            return (
                <Fade bottom key={article.title}>
                    <li>
                        <ExperienceCard>
                            <ExperienceContent>
                                <ExperienceIcon>
                                    <img src={article.image} alt={article.title} />
                                </ExperienceIcon>

                                <ExperienceDescription>
                                    <h2>{article.title}</h2>
                                    <h5>
                                        {article.description} | {article.date}
                                    </h5>
                                    <ul>
                                        {article.keyPoints !== undefined ? (
                                            <>
                                                {article.keyPoints.one && (
                                                    <li>
                                                        <p>{article.keyPoints.one}</p>
                                                    </li>
                                                )}
                                                {article.keyPoints.two && (
                                                    <li>
                                                        <p>{article.keyPoints.two}</p>
                                                    </li>
                                                )}
                                                {article.keyPoints.three && (
                                                    <li>
                                                        <p>{article.keyPoints.three}</p>
                                                    </li>
                                                )}
                                            </>
                                        ) : null}
                                    </ul>
                                </ExperienceDescription>
                            </ExperienceContent>
                        </ExperienceCard>
                    </li>
                </Fade>
            );
        });
    };
    return <ul>{renderExperience(data)}</ul>;
}
