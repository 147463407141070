import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { SideNavigaton } from './SideNavigation';
import { Container } from '../shared-styles/global.styles';
import { device } from '../../utils/theme.util';
import alxLogo from '../../assets/images/alx.png';

const NavContainer = styled.div`
    display: flex;
    align-items: center;

    height: 65px;
    z-index: 100;
    position: relative;
    background-color: transparent;
`;

const NavLogo = styled.img`
    height: 40px;
    width: 40px;
    margin-left: 20px;
`;

const NavContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const NavRight = styled.div`
    ul {
        display: flex;

        li {
            margin-left: 20px;
            list-style: none;

            a {
                color: white;
                text-decoration: none;
                padding-left: 5px;
                border-left: 3px solid transparent;
                transition: all 0.3s ease-in-out 0s;

                &:hover {
                    border-left: 3px solid ${({ theme }) => theme.blue};
                }
            }
        }
    }

    @media ${device.navigation} {
        display: none;
    }
`;

export function Navigation(): JSX.Element {
    return (
        <NavContainer>
            <Container>
                <NavContent>
                    <Fade top>
                        <NavLogo src={alxLogo} alt='Alex' />
                    </Fade>

                    <NavRight>
                        <Fade top cascade>
                            <ul>
                                <li>
                                    <a href='#about'>01. About</a>
                                </li>
                                <li>
                                    <a href='#experience'>02. Experience</a>
                                </li>
                                <li>
                                    <a href='#portfolio'>03. Portfolio</a>
                                </li>
                                <li>
                                    <a href='#articles'>04. Articles</a>
                                </li>
                                <li>
                                    <a href='#contact'>05. Contact</a>
                                </li>
                            </ul>
                        </Fade>
                    </NavRight>
                </NavContent>
            </Container>
            <SideNavigaton />
        </NavContainer>
    );
}
