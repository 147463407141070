import React from 'react';
import styled from 'styled-components';
import Scramble from 'react-scramble';

interface AppProps {
    text: string;
}

const Text = styled(Scramble)`
    font-size: 288px;
    color: #0c0c0c;
    position: absolute;
    margin-top: -133px;
    z-index: 0;
    white-space: nowrap;
`;

export function ScrambleTxt({ text }: AppProps): JSX.Element {
    return (
        <Text
            preScramble
            mouseEnterTrigger='start'
            text={text}
            steps={[
                {
                    roll: 10,
                    action: '+',
                    type: 'all',
                },
                {
                    action: '-',
                    type: 'forward',
                },
            ]}
        />
    );
}
