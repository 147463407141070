import styled, { createGlobalStyle } from 'styled-components';
import { device } from '../../utils/theme.util';

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Arame';
        src: url('/fonts/Arame-Regular.woff2') format('woff2'),
            url('/fonts/Arame-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'BlenderPro';
        src: url('/fonts/BlenderPro-Book.woff2') format('woff2'),
            url('/fonts/BlenderPro-Book.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'BlenderPro';
        src: url('/fonts/BlenderPro-Medium.woff2') format('woff2'),
            url('/fonts/BlenderPro-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }


    @font-face {
        font-family: 'BlenderPro';
        src: url('/fonts/BlenderPro-Bold.woff2') format('woff2'),
            url('/fonts/BlenderPro-Bold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }


    * {
        margin: 0;
        padding: 0;
        font-family: 'Arame', Arial, Helvetica, sans-serif !important;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #0000;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #777;
    }

    button {
        cursor: pointer;
        border-top: none;
        border: none;
    }

    body {
        min-height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        background: #000;
    }

    #header {
        position: absolute;
        right: 0;
    }

    @keyframes grain {
        0%,
        100% {
            transform: translate(0, 0);
        }
        10% {
            transform: translate(-5%, -10%);
        }
        20% {
            transform: translate(-15%, 5%);
        }
        30% {
            transform: translate(7%, -25%);
        }
        40% {
            transform: translate(-5%, 25%);
        }
        50% {
            transform: translate(-15%, 10%);
        }
        60% {
            transform: translate(15%, 0%);
        }
        70% {
            transform: translate(0%, 15%);
        }
        80% {
            transform: translate(3%, 35%);
        }
        90% {
            transform: translate(-10%, 10%);
        }
    }
`;

export const Container = styled.div`
    width: 1205px;
    margin: 0px auto;

    @media ${device.laptopM} {
        width: 1105px;
    }

    @media ${device.laptopS} {
        width: 905px;
    }

    @media ${device.tabletL} {
        width: 90%;
    }
`;

export const ContentContainer = styled.div`
    width: 984px;
    margin: 0px auto;
    @media ${device.laptopS} {
        width: 800px;
    }
    @media ${device.tabletL} {
        width: 77%;
    }
`;

export const CategoryHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    z-index: 30;

    h1 {
        font-size: 36px;
        color: ${({ theme }) => theme.white};
    }

    p {
        font-size: 17px;
        font-family: 'BlenderPro', Arial !important;
        text-transform: uppercase;
        color: ${({ theme }) => theme.gray400};
        width: auto;
        text-align: center;
        border-bottom: 1px solid ${({ theme }) => theme.gray600};
    }
`;
