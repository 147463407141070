import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { ContentContainer, CategoryHeader } from '../../shared-styles/global.styles';
import { ScrambleTxt } from '../../../utils/scramble.util';
import { UniLyfeCard } from './cards/UniLyfe';
import { FeedCitiCard } from './cards/FeedCiti';
import { BuddiUpCard } from './cards/BuddiUp';
import { ReactAutoCard } from './cards/ReactAuto';
import { BublCard } from './cards/Bubl';
import { CleanEarthCard } from './cards/CleanEarth';

const PortfolioContainer = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.black};
    padding-top: 190px;
    padding-bottom: 44px;
    z-index: 50;
    overflow: hidden;
`;

const ProjectGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(298px, 1fr));

    grid-gap: 15px;
`;

export function Portfolio(): JSX.Element {
    return (
        <PortfolioContainer id='portfolio'>
            <Fade delay={500}>
                <ScrambleTxt text='506f7274666f6c696f' />
            </Fade>
            <ContentContainer>
                <CategoryHeader>
                    <h1>Portfolio</h1>
                    <p>Projects I've worked on.</p>
                </CategoryHeader>
                <ProjectGrid>
                    <UniLyfeCard />
                    <FeedCitiCard />
                    <BublCard />
                    <CleanEarthCard />
                    <BuddiUpCard />
                    <ReactAutoCard />
                </ProjectGrid>
            </ContentContainer>
        </PortfolioContainer>
    );
}
