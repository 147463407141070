import React from 'react';
import styled from 'styled-components';
import { FaGithub, FaLinkedinIn } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';

import { device } from '../../utils/theme.util';

const SocialContainer = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 100;
    margin-bottom: 25px;

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        li {
            display: flex;
            list-style: none;
        }
    }

    @media ${device.tabletS} {
        display: none;
    }
`;

const ListItem = styled.li.attrs((props) => ({
    className: props.className,
}))`
    list-style: none;

    &.Icon {
        a {
            font-size: 24px;
            text-decoration: none;
            color: ${({ theme }) => theme.gray400};
            transition: all 0.3s ease-in-out 0s;

            &:hover {
                color: ${({ theme }) => theme.red};
            }
        }
    }
`;

const PersonalAddress = styled.a`
    font-family: 'BlenderPro', Arial !important;
    font-weight: 400;
    font-size: 17px;
    text-decoration: none;
    color: ${({ theme }) => theme.gray400};
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    transition: all 0.3s ease-in-out 0s;

    &:hover {
        color: ${({ theme }) => theme.red};
    }
`;

const Divider = styled.hr`
    height: 45px;
    width: 1px;
    border: none;
    margin: 9px 0px;
    background-color: ${({ theme }) => theme.gray600};
`;

export function SocialGutter(): JSX.Element {
    return (
        <SocialContainer>
            <Fade top cascade>
                <ul>
                    <ListItem>
                        <PersonalAddress href='mailto:me@alexbarksdale.com'>
                            me@alexbarksdale.com
                        </PersonalAddress>
                    </ListItem>

                    <ListItem>
                        <Divider />
                    </ListItem>

                    <ListItem className='Icon'>
                        <a
                            href='https://www.linkedin.com/in/alexbarksdale/'
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            <FaLinkedinIn />
                        </a>
                    </ListItem>
                    <ListItem className='Icon'>
                        <a
                            href='https://github.com/alexbarksdale'
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            <FaGithub />
                        </a>
                    </ListItem>
                </ul>
            </Fade>
        </SocialContainer>
    );
}
