import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { CategoryHeader, ContentContainer } from '../../shared-styles/global.styles';
import { Articles } from './Articles';
import articles from '../../../assets/articles.json';
import { ScrambleTxt } from '../../../utils/scramble.util';

const ArticleContainer = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.black};
    padding: 190px 0px 50px 0px;
    overflow: hidden;
`;

export function ArticleContent(): JSX.Element {
    return (
        <ArticleContainer id='articles'>
            <Fade delay={500}>
                <ScrambleTxt text='41727469636c6573' />
            </Fade>
            <ContentContainer>
                <CategoryHeader>
                    <h1>Articles</h1>
                    <p>Read my recent articles.</p>
                </CategoryHeader>
                <Articles data={articles} />
            </ContentContainer>
        </ArticleContainer>
    );
}
