import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { renderHeader } from './header/header';
import SmoothScroll from 'smooth-scroll';

// Render three.js header
renderHeader();

// Create smooth scroll navigation
new SmoothScroll('a[href*="#"]');

ReactDOM.render(<App />, document.getElementById('root'));
