import React from 'react';
import { FaGithub } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';

import buddiup from '../../../../assets/images/thumbnails/buddiup.png';
import {
    CardHover,
    CardContainer,
    CardContent,
    Thumbnail,
    TypeLinks,
    ProjectDesc,
} from '../../../shared-styles/card-styles';

export function BuddiUpCard(): JSX.Element {
    return (
        <CardHover>
            <Fade bottom cascade>
                <CardContainer>
                    <CardContent>
                        <Thumbnail src={buddiup} alt='BuddiUp Image' />
                        <div>
                            <TypeLinks>
                                <span>
                                    <h5>Type</h5>
                                    <p>Web App</p>
                                </span>
                                <a
                                    href='https://github.com/BuddiUp'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <FaGithub />
                                </a>
                            </TypeLinks>
                            <ProjectDesc>
                                <h1>BuddiUp</h1>
                                <p>
                                    BuddiUp is a platform that allows users to easily find
                                    others with similar interests and connect together.
                                </p>
                                <ul>
                                    <li>Javascript</li>
                                    <li>React</li>
                                    <li>React Redux</li>
                                    <li>Django</li>
                                </ul>
                            </ProjectDesc>
                        </div>
                    </CardContent>
                </CardContainer>
            </Fade>
        </CardHover>
    );
}
